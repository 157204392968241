import { CdkPortal } from '@angular/cdk/portal';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  SiSidePanelService,
  SiToastNotificationService,
} from '@simpl/element-ng';
import { first, Subscription, tap } from 'rxjs';

import { LcasApiClientService } from '../../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { DeviceCommissioningService } from '../../../../shared/device-commissioning/device-commissioning.service';
import {
  WIRELESS_ROOM_SENSORS,
  WirelessRoomSensorType,
} from '../../../../shared/models/business/wireless-room-sensor-type.enum';
import { DeviceDetailsRow } from '../../../shared/generic-components/devices-details/models/device-details-row.model';
import { DeviceTableConfig } from '../../../shared/generic-components/devices-details/models/device-table-config.model';
import { LocationType } from '../../../shared/models/location-type.enum';
import { LocationNavigationService } from '../../../shared/services/location-navigation.service';
import { MainDetailContainerService } from '../../../shared/services/main-detail-container.service';
import { StoreBridgeService } from '../../../shared/services/store-bridge.service';

@Component({
  selector: 'app-planned-devices',
  templateUrl: './planned-devices.component.html',
  providers: [LocationNavigationService],
})
export class PlannedDevicesComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column flex-grow-1';

  @ViewChild('sidePanelContent', { read: CdkPortal })
  sidePanelContent?: CdkPortal;

  selectedLocationType: LocationType = LocationType.Floor;

  selectedFloorId?: string;

  selectedDetailRow?: DeviceDetailsRow;

  deviceTableConfig: DeviceTableConfig = {
    showReadonlyDeviceName: true,
    showMoveActionButton: false,
    showAddDevicesButton: false,
    showAssignButton: true,
  };

  private newLocationToNavigateSubscription: Subscription = new Subscription();

  constructor(
    private siSidePanelService: SiSidePanelService,
    private siToastNotificationService: SiToastNotificationService,
    private storeBridgeService: StoreBridgeService,
    private locationNavigationService: LocationNavigationService,
    private mainDetailContainerService: MainDetailContainerService,
    private deviceCommissioningService: DeviceCommissioningService,
    private lcasApiClientService: LcasApiClientService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.siSidePanelService.close();
    this.completeSubscriptions();
  }

  setSelectedLocationType(locationType: LocationType) {
    this.selectedLocationType = locationType;
    if (locationType === LocationType.Room) {
      this.mainDetailContainerService.notifyDetailsActive(true);
    }
  }

  closeDetailPanel() {
    this.siSidePanelService.close();
  }

  assignDevice(row: DeviceDetailsRow): void {
    if (this.isValidWirelessDevice(row)) {
      this.lcasApiClientService
        .getDevices({ type: ['BrdRout'] })
        .pipe(
          first(),
          tap((devices) => {
            if (
              devices.find(({ setupStatus }) => setupStatus === 'COMMISSIONED')
            ) {
              this.deviceCommissioningService.navigateToDeviceCommissioning(
                row.id,
                row.type,
                this.route,
              );
            } else {
              this.siToastNotificationService.queueToastNotification(
                'danger',
                'COMMISSIONING.TOAST.MESSAGE_NO_COMMISSIONED_BORDERROUTER',
                '',
              );
            }
          }),
        )
        .subscribe();
    } else {
      this.deviceCommissioningService.navigateToDeviceCommissioning(
        row.id,
        row.type,
        this.route,
      );
    }
  }

  openDetails(row: DeviceDetailsRow): void {
    this.selectedDetailRow = row;
    this.siSidePanelService.setSidePanelContent(this.sidePanelContent);
    this.siSidePanelService.open();
  }

  private initSubscriptions(): void {
    this.newLocationToNavigateSubscription.add(
      this.storeBridgeService.newLocationToNavigate$.subscribe(
        ({ floorId, locationId }) => {
          this.locationNavigationService.initNavigation(
            floorId,
            locationId,
            true,
          );
        },
      ),
    );
  }

  private isValidWirelessDevice(device: DeviceDetailsRow): boolean {
    return (
      ['RadVlvActr', 'Rout'].includes(device.type) ||
      WIRELESS_ROOM_SENSORS.includes(device.type as WirelessRoomSensorType)
    );
  }

  private completeSubscriptions(): void {
    this.newLocationToNavigateSubscription.unsubscribe();
  }
}
