<app-main-detail-container>
  <ng-template #masterData let-viewData="viewData">
    <app-locations-tree-view
      slot="masterData"
      *ngIf="viewData.treeItems.length"
      class="mb-9 w-100"
      [treeItems]="viewData.treeItems"
      [showActionButtons]="false"
      (locationType)="setSelectedLocationType($event)"
      (selectFloor)="selectedFloorId = $event"
      (closeDetails)="closeDetailPanel()"
    >
    </app-locations-tree-view>
  </ng-template>

  <ng-template #devicesDetails let-viewData="viewData">
    <div slot="details" class="card elevation-1">
      <app-devices-details
        class="position-relative"
        [siBackdropWithSpinner]="viewData.isLoading"
        [selectedLocation]="viewData?.selectedLocation"
        [deviceTableConfig]="deviceTableConfig"
        (assignClick)="assignDevice($event)"
        (detailsClick)="openDetails($event)"
      ></app-devices-details>
    </div>
  </ng-template>
</app-main-detail-container>

<ng-template #sidePanelContent cdkPortal>
  <si-side-panel-content [heading]="selectedDetailRow?.name || ''">
    <app-details-card
      *ngIf="selectedDetailRow"
      [device]="selectedDetailRow"
    ></app-details-card>
  </si-side-panel-content>
</ng-template>
