<div
  class="h-100 w-100 d-flex flex-column elevation-1 position-absolute overflow-auto"
  *ngIf="rows$ | async as rows"
>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <ngx-datatable
        siResizeObserver
        #table
        *ngIf="rows.length; else emptyRoom"
        class="table-element h-100 devices-table-scrollbar flex-grow-1 si-layout-fixed-height"
        [cssClasses]="tableConfig.cssClasses"
        [headerHeight]="tableConfig.headerHeight"
        [rows]="rows"
        [rowHeight]="tableConfig.rowHeightSmall"
        [columnMode]="tableConfig.columnMode"
        [footerHeight]="0"
        (siResizeObserver)="recalculateTable()"
      >
        <ngx-datatable-column
          [width]="tableConfig.actionsColumnWidth"
          [resizeable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <img
              class="device-image"
              [src]="
                (mediaBaseUrl || '') +
                (row.device.thumbnail || '/assets/images/device-fallback.svg')
              "
              [width]="40"
              alt="Device image"
              [attr.data-testid]="
                'device-' + row.device.instance?.type + '-image'
              "
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          prop="device.name"
          [name]="'GLOBALS.TABLE.HEADER.NAME' | translate"
          [headerClass]="tableConfig.headerClass"
          [cellClass]="tableConfig.cellClass"
          [minWidth]="tableConfig.deviceNameColumnMinWidth"
          [sortable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <si-form-container
              *ngIf="!deviceTableConfig?.showReadonlyDeviceName"
              #formContainer
              [form]="form"
              [errorCodeTranslateKeyMap]="errorCodeTranslateKeyMap"
              [controlNameTranslateKeyMap]="controlNameTranslateKeyMap"
              [disableContainerBreakpoints]="true"
            >
              <div si-form-container-content class="d-flex flex-column">
                <si-form-item
                  [ngClass]="
                    formContainer.getValidationErrors(row.device.id).length
                      ? 'mb-0'
                      : 'mb-4'
                  "
                >
                  <input
                    type="text"
                    class="form-control mt-8"
                    [formControl]="$any(form.controls[row.device.id])"
                    [attr.data-testid]="
                      'device-' + row.device.instance?.model + '-name-input'
                    "
                    (keypress)="onInputKeyPress($event, row.device.id)"
                    [value]="row.device.name"
                  />
                  <div class="invalid-feedback">
                    @for (
                      error of formContainer.getValidationErrors(row.device.id);
                      track $index
                    ) {
                      <span>
                        {{
                          error.errorCodeTranslationKey
                            ? (error.errorCodeTranslationKey
                              | translate: error.errorParams)
                            : error.errorCode
                        }}
                      </span>
                    }
                  </div>
                </si-form-item>

                <span class="si-caption ms-2">{{
                  row.device.instance.model
                }}</span>
              </div>
            </si-form-container>

            <div
              *ngIf="deviceTableConfig?.showReadonlyDeviceName"
              class="d-flex flex-column"
            >
              <span class="si-body-2 mb-5">{{ row.device.name }}</span>
              <span class="si-caption">{{ row.device.instance.model }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        @if (deviceTableConfig?.showAssignButton) {
          <ngx-datatable-column [resizeable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex justify-content-end mx-sm-5">
                <button
                  *ngIf="row.device.setupStatus !== 'COMMISSIONED'"
                  type="button"
                  [attr.data-testid]="
                    'device-' + row.device.instance?.model + '-assign-btn'
                  "
                  class="btn btn-primary d-none d-sm-block"
                  (click)="assignClick.emit(row.device)"
                >
                  <i class="icon element-scan-qr-code"></i>
                  <span>
                    {{ 'GLOBALS.BUTTON.ASSIGN' | translate }}
                  </span>
                </button>
                <button
                  *ngIf="row.device.setupStatus !== 'COMMISSIONED'"
                  (click)="assignClick.emit(row.device)"
                  type="button"
                  [attr.data-testid]="
                    'device-' + row.device.instance?.model + '-assign-mobile-btn'
                  "
                  class="btn btn-circle btn-sm btn-primary element-scan-qr-code d-block d-sm-none"
                  aria-label="badge"
                ></button>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        <ngx-datatable-column [resizeable]="false" [canAutoResize]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="d-flex justify-content-end">
              <button
                *ngIf="row.primaryActions.length"
                type="button"
                [attr.data-testid]="
                  'device-' + row.device.instance?.model + '-details-btn'
                "
                class="btn btn-tertiary d-none d-sm-block me-2"
                (click)="detailsClick.emit(row.device)"
              >
                <i class="icon element-settings"></i>
                <span>
                  {{ 'GLOBALS.BUTTON.DETAILS' | translate }}
                </span>
              </button>
              <button
                *ngIf="row.primaryActions.length"
                (click)="detailsClick.emit(row.device)"
                type="button"
                [attr.data-testid]="
                  'device-' + row.device.instance?.model + '-details-btn'
                "
                class="btn btn-circle btn-sm btn-tertiary element-settings d-block d-sm-none"
                aria-label="badge"
              ></button>
              <si-content-action-bar
                *ngIf="row.secondaryActions.length"
                [secondaryActions]="row.secondaryActions"
                [actionParam]="row.device"
                viewType="expanded"
                [attr.data-testid]="
                  'action-menu-device-' + row.device.instance?.model
                "
              >
              </si-content-action-bar>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </form>
  </ng-container>
</div>

<ng-template #emptyRoom>
  <app-empty-location
    *ngIf="(isLoadingDevices$ | async) === false"
    [selectedLocation]="selectedLocation"
    [showAddDevicesButton]="deviceTableConfig?.showAddDevicesButton"
    (addDevices)="onAddDevicesClick()"
  ></app-empty-location>
</ng-template>

<ng-template #moveDeviceModal let-modalRef="modalRef">
  <app-move-device-modal
    [device]="selectedDevice"
    [location]="selectedLocation"
    [modalRef]="modalRef"
  ></app-move-device-modal>
</ng-template>

<ng-template #forceDeleteModalTemplate let-modalRef="modalRef">
  <app-force-delete-modal
    [modalRef]="modalRef"
    (forceDelete)="initForceDelete()"
  ></app-force-delete-modal>
</ng-template>
