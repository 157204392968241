import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EdgeConnectivityInfo } from '../../../../../shared/models/edge-connectivity-info.model';
import { EnumSelectValue } from './models/enum-select-value.model';

@Component({
  selector: 'app-enum-select',
  templateUrl: './enum-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: EnumSelectComponent,
    },
  ],
})
export class EnumSelectComponent implements ControlValueAccessor {
  @Input() options: EnumSelectValue[] | undefined;

  @Input() edgeConnectivityInfo: EdgeConnectivityInfo | undefined;

  @Output() optionsOpened = new EventEmitter();

  @Output() optionsClosed = new EventEmitter();

  isDisabled = false;

  @ViewChild('selectElement') selectElement!: ElementRef;

  value?: number;

  onChange?: (value: number) => void;

  onTouched?: () => void;

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  controlClicked(): void {
    this.optionsOpened.emit();
    this.onTouched!();
  }
}
